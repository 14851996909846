import * as r from 'runtypes';

export const JobScheduleDayRuntype = r.Record({
    id: r.Number,
    guid: r.String,
    title: r.String,
});
export type JobScheduleDay = r.Static<typeof JobScheduleDayRuntype>;

export const IsDefaultOnCallGig = (j: JobScheduleDay) => j.id === 4;

export const JobScheduleShiftRuntype = r.Record({
    guid: r.String,
    value: r.Number,
});
export type JobScheduleShift = r.Static<typeof JobScheduleShiftRuntype>;

export const JobTypeRuntype = r.Record({
    guid: r.String,
    title: r.String,
});
export type JobType = r.Static<typeof JobTypeRuntype>;

export const JobServiceRuntype = r.Record({
    guid: r.String,
    title: r.String,
});
export type JobService = r.Static<typeof JobServiceRuntype>;

export const JobBenefitRuntype = r.Record({
    guid: r.String,
    title: r.String,
});
export type JobBenefit = r.Static<typeof JobBenefitRuntype>;

export const JobBonusRuntype = r.Record({
    guid: r.String,
    title: r.String,
});
export type JobBonus = r.Static<typeof JobBonusRuntype>;

export type JobMetadataItem = JobType | JobScheduleDay | JobScheduleShift | JobService | JobBenefit | JobBonus;
